<script>
import { computed, nextTick } from 'vue'
import { directive as clickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useProvider } from '/~/composables/provider'
import DrawerModal from '/~/templates/bill-payments/components/drawer-modal.vue'

export default {
  name: 'ew-filters-v2',
  components: {
    BaseButton,
    BaseIcon,
    DrawerModal,
  },
  directives: {
    clickaway,
  },
  props: {
    title: {
      type: String,
      default: 'Sort By',
    },
    isFiltersSelected: {
      type: Boolean,
      default: false,
    },
    isHeaderDisabled: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: '',
    },
    closeOnClickAway: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isAustraliaPostProvider } = useProvider()

    const hasBottomBar = computed(() => {
      if (isAustraliaPostProvider.value) {
        return true
      }

      return false
    })

    return {
      ui,
      hasBottomBar,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    isOpen(value) {
      if (value) {
        nextTick(() => {
          this.$refs.currentButton?.$el.focus()
        })
      }
    },
  },
  methods: {
    handleFilterClick() {
      this.$emit('opened')
      this.toggle()
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
    done() {
      this.$emit('apply')
      this.isOpen = false
    },
    close() {
      this.$emit('close')
      this.isOpen = false
    },
    hide() {
      this.isOpen = false
    },
    doneFiltersModal() {
      this.$emit('apply')
      this.hide()
    },
    closeFiltersModal() {
      this.$emit('close')
      this.hide()
    },
    onClear() {
      this.$emit('clear')
      this.hide()
    },
    onClickAway() {
      if (this.closeOnClickAway) {
        this.close()
      }
    },
  },
}
</script>

<template>
  <div class="relative">
    <slot name="button" :handle-click="handleFilterClick">
      <button
        class="flex items-center justify-center transition duration-100"
        :class="{
          'relative h-10 w-10 rounded border border-solid bg-light hover:border-primary':
            !ui.mobile,
          'fixed right-0 z-10 mb-4 mr-4 h-14 w-14 rounded-full bg-primary text-white shadow-lg':
            ui.mobile,
          'bottom-0': ui.mobile && !hasBottomBar,
          'bottom-14': ui.mobile && hasBottomBar,
        }"
        aria-label="filter"
        aria-haspopup="menu"
        @click="handleFilterClick"
      >
        <base-icon
          :class="{
            'text-eonx-neutral-800': !ui.mobile,
            'text-white': ui.mobile,
          }"
          :svg="ui.mobile ? 'v2/custom/filter' : 'plain/filter-v2'"
          size="md"
          alt="filter"
        />
        <span
          v-if="isFiltersSelected"
          class="absolute right-0 top-0 h-2.5 w-2.5 rounded-full bg-fg-error sm:-mr-[5px] sm:-mt-[5px]"
          :style="{
            marginTop: ui.mobile && '3px',
            marginRight: ui.mobile && '3px',
          }"
        />
      </button>
    </slot>
    <template v-if="!ui.mobile">
      <transition
        enter-class="scale-75 -translate-y-2.5 opacity"
        leave-to-class="scale-75 -translate-y-2.5 opacity-0"
      >
        <div
          v-if="isOpen"
          v-clickaway="onClickAway"
          class="absolute right-0 top-full z-20 mt-2.5 flex max-h-screen-60 min-w-80 origin-top-right transform flex-col overflow-hidden rounded border bg-light shadow-md duration-100"
          @keyup.esc="close"
        >
          <div
            v-if="!isHeaderDisabled"
            class="flex h-14 shrink-0 items-center justify-between pl-5"
          >
            <h3 class="leading-none">
              {{ title }}
            </h3>
            <div v-if="isFiltersSelected" class="space-x-2.5 px-5">
              <base-button
                ref="currentButton"
                look="link"
                size="md"
                @click="onClear"
              >
                Clear all
              </base-button>
              <base-button look="link" size="md" @click="done">
                Done
              </base-button>
            </div>
            <base-button
              v-else
              ref="currentButton"
              :size="24"
              class="mr-5"
              icon="plain/close"
              title="Filters"
              alt="close"
              @click="close"
            />
          </div>
          <div class="flex grow flex-col overflow-y-auto">
            <slot />
          </div>
        </div>
      </transition>
    </template>
    <template v-else>
      <drawer-modal :visible="isOpen" @hide="close">
        <div class="flex shrink-0 items-center justify-between pt-6">
          <span class="text-xl font-bold text-eonx-neutral-800">
            {{ title }}
          </span>
          <base-button v-if="isFiltersSelected" look="link" @click="onClear">
            Clear all filters
          </base-button>
        </div>
        <template #menu>
          <slot name="menu" />
        </template>

        <div class="z-0 flex-1 overflow-y-auto">
          <slot />
        </div>
        <div class="flex space-x-2.5 py-4">
          <base-button
            class="flex-1"
            look="outlined-color"
            @click="closeFiltersModal"
          >
            Cancel
          </base-button>
          <base-button class="flex-1" @click="doneFiltersModal">
            Save
          </base-button>
        </div>
      </drawer-modal>
    </template>
  </div>
</template>
